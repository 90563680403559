.dropdown {
	.dropdown-toggle {
		padding: 0.625em 0.625em;
		color: $black;
		font-size: 0.8em;
		font-family: $font1-700;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		letter-spacing: 0.1em;
	}
	.dropdown-menu {
		border-radius: 0;
		border: 1px solid $light-gray-5;
		box-shadow:
			0px 2.2px 2.2px rgba(0, 0, 0, 0.07),
			0.1px 5.3px 5.3px rgba(0, 0, 0, 0.101),
			0.1px 10px 10px rgba(0, 0, 0, 0.125),
			0.2px 17.9px 17.9px rgba(0, 0, 0, 0.149),
			0.4px 33.4px 33.4px rgba(0, 0, 0, 0.18),
			1px 80px 80px rgba(0, 0, 0, 0.25)
		;
		.dropdown-item {
			color: $black;
			font-size: 0.8em;
			font-family: $font1-700;
			font-weight: 400;
			line-height: 1;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			&.active {
				color: $dark-gray-80;
				background-color: $light-gray-5 !important;
			}
		}
		.dropdown-divider {
			margin: 0.125em 0;
		}
	}
}
