.block-banner-outer-container {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

// Swiper JS Classes
.banner-slide {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	position: relative;
	z-index: 1;
	width: 100vw;
	height: 100%;
}
.banner-image {
	width: 100%;
	height: 100%;
}


.banner-overlay {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: rgba($dark-gray-80, 0.5);
}

.container-caption {
	.banner-caption {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 2.5rem;
		@media (max-width: 575.9px) {
			width: 100%;
		}
		.caption-headline-block {
			position: relative;
			margin: 0;
			width: 60vw;
			@media (max-width: 1199.9px) {
				width: 70vw;
			}
			@media (max-width: 991.9px) {
				width: 80vw;
			}
			@media (max-width: 767.9px) {
				width: 90vw;
			}
			@media (max-width: 575.9px) {
				width: 100vw;
			}
			.caption-headline h1 {
				margin: 0;
				padding: 0;
				font-family: $font1-400;
				font-weight: 400;
				font-size: clamp(3.75rem, -webkit-calc(3.75rem + 3.25 * ((100vw - 62em) / 39)), 7.0rem);
				font-size: clamp(3.75rem, -moz-calc(3.75rem + 3.25 * ((100vw - 62em) / 39)), 7.0rem);
				font-size: clamp(3.75rem, calc(3.75rem + 3.25 * ((100vw - 62em) / 39)), 7.0rem);
				line-height: 0.85;
			}
		}
		hr.caption-divider {
			overflow: visible; /* For IE */
			padding: 0;
			margin: 0 0 0.625em 0;
			border: none;
			border-top: 1px solid $dark-gray-80;
			color: $light-gray-5;
			text-align: right;
		}
		.caption-body {
			position: relative;
			margin: 0;
			padding: 0;
			width: 75vw;
			@media (max-width: 1199.9px) {
				width: 80vw;
			}
			@media (max-width: 991.9px) {
				width: 85vw;
			}
			@media (max-width: 767.9px) {
				width: 95vw;
			}
			@media (max-width: 575.9px) {
				width: 100vw;
			}
			p {
				line-height: 1;
			}
			h1 {
				@extend .display-1;
				margin: 0;
				padding: 0;
				font-family: $font1-400;
				// font-weight: 400;
				// font-size: clamp(3.75rem, -webkit-calc(3.75rem + 3.25 * ((100vw - 62em) / 39)), 7.0rem);
				// font-size: clamp(3.75rem, -moz-calc(3.75rem + 3.25 * ((100vw - 62em) / 39)), 7.0rem);
				// font-size: clamp(3.75rem, calc(3.75rem + 3.25 * ((100vw - 62em) / 39)), 7.0rem);
				line-height: 0.85;
				// text-transform: uppercase;
			}
		}
	}
}

#banner-down-arrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	text-align: center;
	pointer-events: none;
}

#banner-down-arrow a {
	position: absolute;
	bottom: 2.0em;
	left: 0;
	width: 100vw;
	-webkit-animation: anim-banner-down-arrow 2s infinite;
	animation: anim-banner-down-arrow 2s infinite;
	.fa, .fac {
		font-size: 2.0em;
		color: $dark-gray-80;
		&:hover {
			color: $dark-gray-80;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $dark-gray-80;
		}
	}
}

@-webkit-keyframes anim-banner-down-arrow {
	0% {
		-webkit-transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(2.0em);
		opacity: 0;
	}
}

@keyframes anim-banner-down-arrow {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(2.0em);
		opacity: 0;
	}
}