hr.divider-brand {
	border-top: 1px solid $dark-gray-80;
	color: $dark-gray-80;
}

hr.divider-gray {
	border-top: 1px solid $light-gray-15;
	color: $light-gray-15;
}

hr.divider-white {
	border-top: 1px solid $light-gray-5;
	color: $light-gray-5;
}

hr.divider-short {
	width: 6.25em;
}

// hr.divider-left {
// 	margin: 0.625em 0 1.25em 0;
// 	@media (max-width: 575.9px) {
// 		margin: 0.625em auto 1.25em auto;
// 	}
// }
