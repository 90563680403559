@charset 'UTF-8';

@import "../node_modules/bootstrap/scss/_functions.scss";
@import "../node_modules/bootstrap/scss/_variables.scss";
@import "../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@import
	'abstracts/variables',
	'abstracts/functions',
	'abstracts/mixins';

@import
	'base/base',
	'base/fonts',
	'base/typography',
	'base/dividers',
	'base/helpers',
	'base/utility';

@import
	'components/navigation',
	'components/header',
	'components/footer',
	'components/buttons',
	'components/banners',
	'components/animations',
	'components/backgrounds',
	'components/cards',
	'components/dropdowns',
	'components/forms',
	'components/images',
	'components/page-title',
	'components/page-intro',
	'components/pagination',
	'components/sliders',
	'components/snap',
	'components/video',
	'components/blocks';

@import
	'templates/home',
	'templates/about',
	'templates/contact',
	'templates/news',
	'templates/people';
