.entry-pages-contact {
	.page-title-container {
		padding-bottom: 3.125em;
	}
	.contact-form-container {
		padding: 0.625em 2.25em 2.0em 0;
		h3 {
			margin-bottom: 0;
		}
	}
	.contact-location-intro {
		padding: 2.25em 0 0 2.25em;
	}
	.contact-location-info-container {
		padding: 2.25em 0 0 2.25em;
		.contact-location-img {
			margin-bottom: 1.25em;
		}
		h4 {
			color: $dark-gray-80;
		}
		hr {
			margin: 0.625em 0;
		}
		p {
			font-size: 0.9375em;
			line-height: 1.25;
			color: $dark-gray-80;
		}
		a {
			color: $dark-gray-80;
			text-decoration: none;
			&:hover {
				color: $dark-gray-80;
				text-decoration: none;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $dark-gray-80;
				text-decoration: none;
			}
		}
	}
	.contact-location-map-container {
		padding: 2.25em;
	}
}