.link-text-nav, .link-text-subnav {
	color: $dark-gray-80;
	font-family: $font1-100;
	font-weight: 100;
	font-size: 1.0rem;
	font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.625 * ((100vw - 36em) / 39)), 1.625rem);
	font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.625 * ((100vw - 36em) / 39)), 1.625rem);
	font-size: clamp(1.0rem, calc(1.0rem + 0.625 * ((100vw - 36em) / 39)), 1.625rem);
	line-height: 1;
	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	-ms-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
	&:hover {
		color: $black !important;
		text-decoration: underline;
		text-decoration-thickness: from-font;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $dark-gray-80 !important;
		text-decoration: underline;
		text-decoration-thickness: from-font;
	}
	.fac {
		color: $cool-gray-10;
	}
}

.link-text-subnav {
	color: $cool-gray-10 !important;
	font-family: $font1-400;
	font-weight: 400;
	.fac {
		font-size: 0.8em;
		transform: translateY(-0.125em);
		color: $cool-gray-10;
		&:hover {
			color: $dark-gray-80;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $dark-gray-80;
		}
	}
}

.link-text-sm {
	color: $dark-gray-80;
	font-family: $font1-400;
	font-weight: 400;
	font-size: 0.5em;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $black;
		text-decoration: underline;
		text-decoration-thickness: from-font;
		.fa, .fac {
			color: $dark-gray-80;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $dark-gray-80;
		text-decoration: none;
		.fa, .fac {
			color: $cool-gray-10;
		}
	}
	.fa, .fac {
		font-size: 0.8em;
		transform: translateX(0.25em);
	}
}

.nav-tabs {
	position: relative;
	z-index: 50;
}

header {
	nav.navbar {
		#header-menu {
			background: none;
			padding: 1.0em;
			.header-logo-menu {
				display: none;
			}
			li {
				display: block;
				transition-duration: 0.5s;
				&:hover {
					cursor: default;
					&.pointer {
						cursor: pointer;
					}
				}
			}
			ul li ul {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				transition: all 0.5s ease-out;
				display: none;
				padding-top: 0.625em;
				a {
					color: $dark-gray-80;
					background-color: $light-gray-5;
					padding: 0.625em 0 !important;
					&:hover {
						color: $black;
					}
				}
			}
			ul li:hover > ul,
			ul li ul:hover {
				visibility: visible;
				opacity: 1;
				display: block;
			}
			ul li ul li {
				clear: both;
				width: 100%;
			}
		}
		.navbar-nav {
			margin-left: auto;
			margin-right: auto;
		}
		.nav-link, .dropdown-item {
			padding: 0.3125em 0 !important;
			margin: 0 0.625em !important;
			border-bottom: 1px solid rgba($black, 0);
			-webkit-transition: all 0.5s ease-out;
			-moz-transition: all 0.5s ease-out;
			-ms-transition: all 0.5s ease-out;
			-o-transition: all 0.5s ease-out;
			transition: all 0.5s ease-out;
			.nav-link-arrow {
				padding: 0 0 0 0.625em;
				color: $cool-gray-10;
			}
			&:hover {
				text-decoration: none;
				border-bottom: 1px solid rgba($black, 0.25)
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				text-decoration: none;
				border-bottom: 1px solid rgba($black, 1);
			}
			&.active  {
				text-decoration: none;
				border-bottom: 1px solid rgba($black, 1);
			}
		}
		.dropdown-item {
			margin: 0.625em 0;
			padding: 0.625em 0;
			width: auto;
			&:hover {
				color: $black;
				background: none;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $dark-gray-80 !important;
				background: none !important;
			}
		}
		.dropdown-menu {
			background-color: $light-gray-5;
		}
		.nav-item {
			margin: 0;
			.nav-link {
				color: $dark-gray-80;
			}
			&:hover {
				.nav-link {
					color: $black;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				.nav-link {
					color: $dark-gray-80 !important;
				}
			}
		}
	}
	.navbar-toggler {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: $dark-gray-80;
		&:hover:not(.collapsed) {
			.navbar-toggler-icon {
				margin: 0;
				&>span {
					margin: 6px 0;
				}
			}
		}
		&:not(.collapsed) {
			.navbar-toggler-icon {
				margin: 0;
				&>span {
					margin: 6px 0;
					background-color: $dark-gray-80 !important;
					&:first-child {
						-webkit-transform: translateY(9px) rotate(45deg);
						-moz-transform: translateY(9px) rotate(45deg);
						-ms-transform: translateY(9px) rotate(45deg);
						-o-transform: translateY(9px) rotate(45deg);
						transform: translateY(9px) rotate(45deg);
					}
					&:nth-child(2) {
						opacity: 0;
					}
					&:last-child {
						-webkit-transform: translateY(-9px) rotate(-45deg);
						-moz-transform: translateY(-9px) rotate(-45deg);
						-ms-transform: translateY(-9px) rotate(-45deg);
						-o-transform: translateY(-9px) rotate(-45deg);
						transform: translateY(-9px) rotate(-45deg);
					}
				}
			}
		}
		.navbar-toggler-icon {
			margin: 4px 0;
			-webkit-transition: margin 0.25s;
			-moz-transition: margin 0.25s;
			-ms-transition: margin 0.25s;
			-o-transition: margin 0.25s;
			transition: margin 0.25s;
			&>span {
				display: block;
				content: '';
				margin: 4px 0;
				width: 100%;
				height: 3px;
				background: $dark-gray-80;
				border-radius: 1.5px;
				-webkit-transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
				-moz-transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
				-ms-transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
				-o-transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
				transition: transform 0.25s, margin 0.25s, opacity 0.25s, background 0.25s;
			}
			// background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(33,33,33,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
		}
	}
}

header.header-light:not(.nav-down):not(.nav-up) {
	@media (min-width: 575.9px) {
		nav {
			.nav-link, .dropdown-item {
				.nav-link-arrow {
					padding: 0 0 0 0.625em;
					color: $light-gray-15;
				}
				&:hover {
					color: $light-gray-5 !important;
					border-bottom: 1px solid rgba($light-gray-5, 0.25)
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $light-gray-5 !important;
					border-bottom: 1px solid rgba($light-gray-5, 1);
				}
				&.active {
					color: $light-gray-15 !important;
					border-bottom: 1px solid rgba($light-gray-5, 1);
				}
			}
			.dropdown-item {
				&:hover {
					color: $light-gray-5;
					background: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $light-gray-15 !important;
				}
			}
			.nav-item {
				.nav-link {
					color: $light-gray-15;
				}
				&:hover {
					.nav-link {
						color: $light-gray-5;
					}
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					.nav-link {
						color: $light-gray-15 !important;
					}
				}
			}
		}
	}
	@media (max-width: 575.9px) {
		.navbar-toggler {
			color: $light-gray-15;
			.navbar-toggler-icon {
				// background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
				&>span {
					background: $light-gray-15;
				}
			}
		}
	}
}

@media (max-width: 575.9px) {
	header {
		margin: 0 !important;
		overflow-y: none;
		nav.navbar {
			#header-menu {
				position: absolute;
				top: -0.9375rem;
				left: 0;
				right: 0;
				width: 100vw !important;
				margin: 0 !important;
				padding: 0;
				background: none;
				position: relative;
				z-index: 1;
				width: 100%;
				text-align: center;
				&.show {
					margin: 0;
					padding: 0;
					min-height: 100vh;
					@supports (height:100dvh) {
						min-height: 100dvh;
					}
					max-height: -moz-available;
					max-height: -webkit-fill-available;
					max-height: fill-available;
					overflow-y: scroll;
				}
				ul li ul {
					visibility: visible;
					opacity: 1;
					position: relative;
					display: block;
					transform: translateX(0);
					padding-left: 1.25em;
				}
				li {
					border: none;
					a {
						color: $dark-gray-80;
						background: none;
						border: none;
						padding: 0.625em 1.875em !important;
						&:hover {
							color: $black;
						}
					}
				}
				.header-logo-menu {
					position: absolute;
					left: 0;
					top: 0.9375rem;
					z-index: 1020;
					display: inline-block;
					background: left center/contain no-repeat;
					text-indent: -9999px;
					width: 180px;
					height: 37px;
					margin: 0 1.875rem;
					background-image: url('/assets/images/branding/KG_logo_BK.png') !important;
					-webkit-transition: background 1.0s linear;
					-moz-transition: background 1.0s linear;
					-ms-transition: background 1.0s linear;
					-o-transition: background 1.0s linear;
					transition: background 1.0s linear;
				}
			}
			.navbar-nav {
				margin: 0;
				padding: 0;
				min-height: 100vh;
				@supports (height:100dvh) {
					min-height: 100dvh;
				}
				max-height: -moz-available;
				max-height: -webkit-fill-available;
				max-height: fill-available;
				overflow-y: scroll;
				border: none;
				float: none;
				z-index: 99;
				display: flex;
				justify-content: center;
				align-items: center;

				background: $light-gray-5;
				.nav-link, .dropdown-item {
					font-size: 3.5rem;
					line-height: 1;
					color: $dark-gray-80 !important;
					text-decoration: none;
					.nav-link-arrow {
						padding: 0 0 0 0.625em;
						color: $dark-gray-80 !important;
					}
					&:hover {
						color: $black !important;
						text-decoration: underline;
						text-decoration-thickness: 0.25rem;
						text-decoration-skip-ink: auto;
						text-underline-offset: 0.5rem;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $black !important;
						text-decoration: underline;
						text-decoration-thickness: 0.25rem;
						text-decoration-skip-ink: auto;
						text-underline-offset: 0.5rem;
					}
					&.active {
						color: $black !important;
						text-decoration: underline;
						text-decoration-thickness: 0.25rem;
						text-decoration-skip-ink: auto;
						text-underline-offset: 0.5rem;
					}
				}
				.dropdown-item {
					&:hover {
						color: $dark-gray-80;
						background: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $dark-gray-80 !important;
					}
				}
				.nav-item {
					.nav-link {
						color: $dark-gray-80;
					}
					&:hover {
						.nav-link {
							color: $black;
						}
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						.nav-link {
							color: $black !important;
						}
					}
				}
			}
		}
		.navbar-toggler {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1020;
			padding: 0 1.875rem;
			// color: $light-gray-15;
			.navbar-toggler-icon {
				// background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
			}
		}	
	}
}

.btn-nav-accordion-button {
	margin: 0;
	padding: 0.625rem 0 !important;
	border-radius: 0 !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid $light-gray-15;
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0 !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	&[aria-expanded="true"] {
		.fac { transform: rotate(180deg); }
	}
	@media (max-width: 575.9px) {
		// margin-bottom: 1.25rem;
	}
	.fac {
		display: inline-block;
		margin-right: 0.3125em;
		line-height: 1;
		transition: transform 300ms ease-in-out;
		transform: rotate(0deg);
		color: $black;
	}
	.category-nav-text {
		display: inline-block;
		margin: 0;
		color: $black;
		font-family: $font1-400;
		font-weight: 400;
		font-size: 1.25rem;
		letter-spacing: 0;
		line-height: 1;
		text-transform: none;
	}
}