/* People Cards */
.people-card-container {
	padding: 1.0em 0 5.0em 0;
}
.people-card {
	margin: 0.625em;
	border-radius: 0;
	width: 100%;
	&:hover {
		& > * {
			cursor: pointer;
		}
		.card-img-overlay-hover {
			opacity: 1;
			pointer-events: initial;
		}
		.card-img {
			.card-img-back {
				opacity: 1.0;
				transform: scale(1.25);
			}
		}
	}
	.card-img {
		top: initial;
		border-radius: 0;
		z-index: 0;
		overflow: hidden;
		.card-img-back {
			opacity: 0.5;
			-webkit-transition: all 3.0s ease-out;
			-moz-transition: all 3.0s ease-out;
			-ms-transition: all 3.0s ease-out;
			-o-transition: all 3.0s ease-out;
			transition: all 3.0s ease-out;
		}
		.card-back-overlay {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: 2;
			pointer-events: none;
		}
	}
	.card-img-overlay {
		border-radius: 0;
		pointer-events: none;
		z-index: 3;
		.card-people-title {
			font-size: 1.625em;
			font-weight: 400;
			color: $dark-gray-80;
			margin: 0;
		}
		.card-people-title-position {
			font-size: 0.875em;
		}
	}
	.card-img-overlay-hover {
		opacity: 0;
		top: initial;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 3.0em;
		background: -moz-linear-gradient(180deg, rgba($light-gray-5,0) 0%, rgba($light-gray-5,0.8) 100%);
		background: -webkit-linear-gradient(180deg, rgba($light-gray-5,0) 0%, rgba($light-gray-5,0.8) 100%);
		background: linear-gradient(180deg, rgba($light-gray-5,0) 0%, rgba($light-gray-5,0.8) 100%);
		background: linear-gradient(180deg, rgba($light-gray-5,0) 0%, rgba($light-gray-5,0.8) 100%);
		-webkit-transition: opacity 1.0s ease-out;
		-moz-transition: opacity 1.0s ease-out;
		-ms-transition: opacity 1.0s ease-out;
		-o-transition: opacity 1.0s ease-out;
		transition: opacity 1.0s ease-out;
		.buttons {
			position: static;
			z-index: 3;
			.btn {
				padding: 1.0em;
			}
		}
	}
}

/* Practice Area Cards */
.practice-attorney-card-container {
	margin: 0;
	padding: 0.5625em 0;
}
.practice-people-card {
	margin: 0.5626em 0;
	padding: 0;
	border-radius: 0;
	display: flex;
	align-items: center;
	.card-img-top {
		width: 50%;
		height: auto;
		top: initial;
		border-radius: 0;
		.card-img-back {
			opacity: 0.5;
		}
		.card-back-overlay {
			position: absolute;
			left: 25%;
			top: 0;
			width: 50%;
			height: 50%;
			// overflow: hidden;
			z-index: 0;
		}
	}
	.card-body {
		border-radius: 0;
		padding: 0.5625em;
		.card-people-title {
			font-size: 1.625em;
			font-weight: 400;
			color: $dark-gray-80;
			margin: 0;
		}
		.card-people-title-position {
			font-size: 0.875em;
		}
	}
}

//Main People Detail Page
.people-header-container {
	position: relative;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.people-header-container-overlay-1 {
		background: linear-gradient(180deg, rgba($dark-gray-80,0.8) 0%, rgba($dark-gray-80,0.25) 33%, rgba($dark-gray-80,0.25) 66%, rgba($dark-gray-80,0.8) 100%);
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.people-header-container-overlay-2 {
		background: radial-gradient(circle, rgba($dark-gray-80,0.25) 0%, rgba($dark-gray-80,0.6) 100%);
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	.people-header-text-overlay {
		position: relative;
		z-index: 3;
		.people-header-info-container {
			color: $light-gray-5;
			a {
				color: $light-gray-5;
			}
			.people-header-divider {
				width: 6.0em;
				border-top: 1px solid $light-gray-15;
				color: $light-gray-15;
				margin-left: 0;
			}
			p {
				font-size: 1.5em;
				color: $light-gray-15;
				margin-bottom: 0.625em;
				line-height: 1;
				a {
					color: $light-gray-5;
				}
			}
			.btn-sm {
				font-size: 1.0em;
				padding: 0.625em 1.25em;
			}
		}
	}
}
.people-container {
	padding: 5.0em 0;
	ul {
		list-style-type: none;
		margin: 0 0 2.5em 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0.625em 0;
			line-height: 1;
			border-bottom: 1px solid $light-gray-15;
			&:first-child { 
				padding-top: 0; 
			}
			&:last-child { 
				border-bottom: none; 
			}
			&::marker {
				content: "";
				display: none;
			}
			b {
				color: $black;
				font-weight: 600;
			}
			&::before {
				content: "";
				display: none;
			}
		}
	}
}
