footer {
	@media (max-width: 575.9px) {
		font-size: 1.0625em;
	}
}

//Footer Top Rows
.footer-top {
	// background-color: $black;
	.footer-top-inner {
		padding: 5.0rem 2.5rem 2.5rem 2.5rem;
		text-align: center;
		.row-top {
		}
		.footer-logo-container { 
			padding: 0 2.5rem 2.5rem 2.5rem;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			@media (max-width: 991.9px) {
				justify-content: center;
			}
			// @media (max-width: 767.9px) {
			// 	padding-right: 45px;
			// }
			.footer-logo {
				display: block;
				background: left center/contain no-repeat;
				width: 100%;
				height: 9.125rem;
				text-indent: -9999px;
				@media (max-width: 1799.9px) {
					height: 8.125rem;
				}
				@media (max-width: 1199.9px) {
					height: 7.125rem;
				}
				@media (max-width: 991.9px) {
					height: 6.125rem;
					background: center center/contain no-repeat;
				}
				// @media (max-width: 767.9px) {
				// 	height: 6.125rem;
				// }
				// @media (max-width: 575.9px) {
				// 	height: 6.125rem;
				// }
			}
		}
		.footer-name-container {

		}
		.footer-links, .footer-brand-links, .footer-name-container {
			// display: flex;
			// justify-content: center;
			// align-items: flex-start;
			@media (max-width: 575.9px) {
				padding: 2.5rem;
			}
			.links-container {
				text-align: left;
				@media (max-width: 575.9px) {
					text-align: center;
				}
				.links-title {
					margin-bottom: 1.25rem;
					font-family: $font1-400;
					font-weight: 400;
					font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
					font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
					font-size: clamp(1.125rem, calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
					text-transform: none;
					// letter-spacing: -.066em;
					// color: $light-gray-15;
					@media (max-width: 575.9px) {
						font-size: 1.25rem;
					}
				}
				ul.unstyled {
					list-style-type: none;
					margin: 0;
					padding: 0;
					li {
						font-family: $font1-400;
						font-weight: 400;
						font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
						font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
						font-size: clamp(1.125rem, calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
						line-height: 1;
						// white-space: nowrap;
						@media (max-width: 575.9px) {
							font-size: 1.25rem;
						}
						a {
							padding: 0.25rem 0 !important;
							// color: $light-gray-15;
							@media (max-width: 575.9px) {
								padding: 0.625rem 0 !important;
							}
							&:hover {
								// color: $light-gray-5;
								text-decoration: underline;
								text-decoration-thickness: from-font;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								// color: $light-gray-5;
								text-decoration: underline;
								text-decoration-thickness: from-font;
							}
						}
					}
				}
				ul.list-2column {
					column-count: 2;
					column-gap: 2.5em;
					@media (max-width: 575.9px) {
						column-count: 1;
					}
				}
			}
		}
	}
}

// Footer Bottom Rows
.footer-bottom {
	// background-color: $black;
	.footer-bottom-inner {
		padding: 2.1875em 2.5em;
		text-align: center;
		// border-top: 1px solid $light-gray-15;
		.row-top {
			padding: 0;
		}
		.row-bottom {
			padding: 0;
		}
		.footer-address-container, .footer-links {
			padding: 0.625rem 0;
		}
		.footer-address, .footer-phone {
			font-size: clamp(1.25rem, -webkit-calc(1.25rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
			font-size: clamp(1.25rem, -moz-calc(1.25rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
			font-size: clamp(1.25rem, calc(1.25rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
			line-height: 1;
			// color: $light-gray-15;
			a {
				// color: $light-gray-5;
				&:hover {
					// color: $dark-gray-80;
					text-decoration: underline;
					text-decoration-thickness: from-font;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					// color: $black;
					text-decoration: underline;
					text-decoration-thickness: from-font;
				}
			}
		}
		.footer-phone {
			padding-top: .625rem;
		}
		.footer-links {
			li.list-inline-item a {
				font-family: $font1-400;
				font-weight: 400;
				font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
				font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
				font-size: clamp(1.125rem, calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
				line-height: 1;
				padding: 0 0.625em 0 0.625em !important;
				// color: $light-gray-15;
				&:hover {
					// color: $light-gray-5;
					text-decoration: underline;
					text-decoration-thickness: from-font;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					// color: $light-gray-5;
					text-decoration: underline;
					text-decoration-thickness: from-font;
				}
			}
		}
		.footer-copyright {
			// padding: 1.25rem 0;
			color: $black;
			font-size: clamp(0.75rem, -webkit-calc(0.75rem + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
			font-size: clamp(0.75rem, -moz-calc(0.75rem + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
			font-size: clamp(0.75rem, calc(0.75rem + 0.25 * ((100vw - 36em) / 39)), 1.0rem);
			line-height: 1;
			@media (max-width: 575.9px) {
				font-size: 1.0rem;
			}
		}
		.footer-social-media {
			.footer-social-icon {
				margin: 0 0.625rem;
				font-family: $font-icons2;
				// color: $light-gray-15;
				line-height: 1;
				&:hover {
					// color: $light-gray-5;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					// color: $light-gray-5;
					text-decoration: none;
				}
			}
			.footer-social-desc {
				display: none;
			}
		}
	}
}
