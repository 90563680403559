.image-slider {
	min-height: auto;
	&.loading {
		//min-height: 66vh;
	}
	width: 100vw;
	//** height: calc(100vh - 10em);
	.image-slider-wrapper {
		.image-slide {
			display: inline-block;
			width: auto;
			// height: 100vh; // Some browsers, including Safari need a height in this context
			// @supports (height:100dvh) {
			// 	height: 100dvh;
			// }
			//max-width: 100vw;
			//max-height: calc(100vh - 10em);
			overflow: hidden;
			.image-slide-image {
				// width: auto;
				// height: 100%;
				max-width: 100vw;
				max-height: 100vh;
				@supports (height:100dvh) {
					max-height: 100dvh;
				}
				opacity: 0.75;
				border: none;
				-webkit-filter: blur(0px) grayscale(0%) contrast(50%);
				-moz-filter: blur(0px) grayscale(0%) contrast(50%);
				-ms-filter: blur(0px) grayscale(0%) contrast(50%);
				-o-bfilter: blur(0px) grayscale(0%) contrast(50%);
				filter: blur(0px) grayscale(0%) contrast(50%);
				-webkit-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-moz-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-ms-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-o-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				transition: filter 1.0s ease-out, opacity 1.0s ease-out;
			}
			figure {
				figcaption {
					margin: 0;
					padding: 0.625rem 0 2.25rem 0;
				}
			}
		}
		.swiper-slide-active {
			.image-slide-image {
				opacity: 1.0;
				border: none;
				-webkit-filter: blur(0) grayscale(0) contrast(100%);
				-moz-filter: blur(0) grayscale(0) contrast(100%);
				-ms-filter: blur(0) grayscale(0) contrast(100%);
				-o-bfilter: blur(0) grayscale(0) contrast(100%);
				filter: blur(0) grayscale(0) contrast(100%);
			}
		}
	}
}

.swiper-button-next, .swiper-button-prev {
	&::after {
		display: inline-block;
		font-family: $font-icons2;
		font-size: 2.625em; 
		font-weight: normal;
		font-style: normal;
		font-variant: normal;
		line-height: 1;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		color: $light-gray-5;
	}
	&:hover {
		&::after {
			color: $light-gray-15;
		}
	}
	&:focus-visible {
		outline: none;
	}
}
.swiper-button-next {
	&::after {
		content: "\f001";
	}
}
.swiper-button-prev {
	&::after {
		content: "\f002";
	}
}


.swiper-pagination {
	.swiper-pagination-bullet {
		width: 0.625em;
		height: 0.625em;
		border-radius: 50%;
		background: none;
		border: 0.125em solid $light-gray-5;
		opacity: 1.0;
		margin: 0 0.1em !important;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		// -webkit-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -moz-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -ms-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -o-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// transition: background-color 0.5s ease-out, border 0.5s ease-out;
		&:hover {
			background-color: $light-gray-5;
			border: 0.3125em solid $light-gray-5;
		}
	}
	.swiper-pagination-bullet-active {
		background-color: $light-gray-15;
		border: 0.125em solid $light-gray-15;
	}
}

