// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
	font-family: 'Berthold Light';
	src: url('/stylesheets/fonts/berthold_akzidenz_grotesk_be_light-webfont.woff2') format('woff2'),
			 url('/stylesheets/fonts/berthold_akzidenz_grotesk_be_light-webfont.woff') format('woff');
}

@font-face {
	font-family: 'Berthold Regular';
	src: url('/stylesheets/fonts/berthold_akzidenz_grotesk_be_regular-webfont.woff2') format('woff2'),
			 url('/stylesheets/fonts/berthold_akzidenz_grotesk_be_regular-webfont.woff') format('woff');
}

@font-face {
	font-family: 'Berthold Bold';
	src: url('/stylesheets/fonts/berthold_akzidenz_grotesk_bold.woff2') format('woff2'),
			 url('/stylesheets/fonts/berthold_akzidenz_grotesk_bold.woff') format('woff');
}
