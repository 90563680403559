.btn {
	font-family: $font1-700;
	font-size: 0.875em;
	// font-weight: 700;
	line-height: 1.0;
	text-transform: uppercase;
	letter-spacing: 0.125em;
	border-radius: 1.5em;
	padding: 0.875em 1.75em 0.75em 1.75em;
	margin: 0.2em 0.1em;
	text-decoration: none !important;
	-webkit-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out, backdrop-filter 1.0s ease-out;
	-moz-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out, backdrop-filter 1.0s ease-out;
	-ms-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out, backdrop-filter 1.0s ease-out;
	-o-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out, backdrop-filter 1.0s ease-out;
	transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out, backdrop-filter 1.0s ease-out;
	&:hover {
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		text-decoration: none;
	}
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0  !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		// -webkit-box-shadow: 0 0 8px rgba($light-gray-5, 0.8)!important;
		// -moz-box-shadow: 0 0 8px rgba($light-gray-5, 0.8) !important;
		// box-shadow: 0 0 8px rgba($light-gray-5, 0.8) !important;
	}
}

.btn-sm {
	font-size: 0.8em;
	padding: 0.75em 1.4375em 0.625em 1.4375em;
	border-radius: 1.25em;
}

.btn-lg {
	font-size: 1.5em;
	padding: 1.125em 1.75em 1.0em 1.75em;
	border-radius: 2.0em;
}

.btn-primary {
	background-color: $dark-gray-80;
	border: none;
	&:hover {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: none;
	}
}

.btn-secondary {
	background-color: $cool-gray-10;
	border: none;
	&:hover {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $light-gray-5;
		background-color: $light-gray-15;
		border: none;
	}
}

.btn-outline-primary {
	background-color: none !important;
	color: $dark-gray-80;
	border: 2px solid $dark-gray-80;
	&:hover {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: 2px solid $dark-gray-80;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: 2px solid $dark-gray-80;
	}
}

.btn-outline-secondary {
	color: $dark-gray-80;
	border: 2px solid $dark-gray-80;
	&:hover {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: 2px solid $dark-gray-80;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $light-gray-5;
		background-color: $light-gray-15;
		border: 2px solid $light-gray-15;
	}
}

.btn-dark {
	background-color: $black;
	border: none;
	&:hover {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: none;
	}
}

.btn-light {
	background-color: $light-gray-5;
	border: none;
	&:hover {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: none;
	}
}

.btn-outline-dark {
	background-color: none;
	color: $black;
	border: 2px solid $black;
	&:hover {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: 2px solid $dark-gray-80;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $light-gray-5;
		background-color: $dark-gray-80;
		border: 2px solid $dark-gray-80;
	}
}

.btn-outline-light {
	background-color: none;
	color: $light-gray-5;
	border: 2px solid $light-gray-5;
	&:hover {
		background: rgba($dark-gray-80, 0.2) !important;
		color: white;
		border: 2px solid white;
		-webkit-backdrop-filter: saturate(180%) blur(8px);
		-moz-backdrop-filter: saturate(180%) blur(8px);
		-ms-backdrop-filter: saturate(180%) blur(8px);
		-o-backdrop-filter: saturate(180%) blur(8px);
		backdrop-filter: saturate(180%) blur(8px);
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		background: rgba($dark-gray-80, 0.2) !important;
		color: white;
		border: 2px solid white;
		-webkit-backdrop-filter: saturate(180%) blur(8px);
		-moz-backdrop-filter: saturate(180%) blur(8px);
		-ms-backdrop-filter: saturate(180%) blur(8px);
		-o-backdrop-filter: saturate(180%) blur(8px);
		backdrop-filter: saturate(180%) blur(8px);
	}
}

.btn-link {
	margin: 0;
	padding: 0;
	color: $dark-gray-80;
	line-height: 1.0;
	text-transform: initial;
	letter-spacing: 0;
	text-decoration: none;
	border: none;
	&:before {
		content: '\25FC';
		display: inline-block;
		margin-right: 0.125em;
		color: $black;
		font-size: 1.375em;
		transform: translateY(0.125em);
	}
	&:hover {
		color: $dark-gray-80;
		text-decoration: underline;
		text-decoration-thickness: from-font;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $dark-gray-80;
		text-decoration: underline;
		text-decoration-thickness: from-font;
		border: none;
	}
}
