// Blocks General
.content-blocks-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-snap-type: y proximity;
	-webkit-overflow-scrolling: touch;
}

.block-outer-container {
	position: relative;
	margin: 0 !important;
	padding: 0 !important;
	// @supports (height:100dvh) {
	// 	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left) !important;
	// }
}

.block-inner-container {
	position: relative;
	padding: 0 !important;
}

.block-scroll-snap-center {
	scroll-snap-align: center;
	scroll-snap-stop: always;
}

.block-outer-container-center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.block-inner-container-center-content {
	display: flex;
	justify-content: center;
	align-items: center;
	.row {
		width: 100%;
	}
}

.block-headline-text {
	margin: 0;
	line-height: 1;
}

.block-subheadline-text, .block-subheadline-text p {
	margin: 0.3125em 0 0 0;
	text-transform: none;
	line-height: 1;
}

.block-button-group-container {
	padding-top: 1.25rem;
}

.block-background-image-overlay {
	position: absolute;
	z-index: -10;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.block-background-image-fixed {
	background-attachment: fixed !important;
	@supports (-webkit-touch-callout: none) {
		background-attachment: scroll !important;
	}
}

.block-background-image-cover {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.block-background-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
	object-fit: cover;
	object-position: 50% 50%;
	overflow: hidden;
}

.block-container-full, .block-container-content {
	position: relative;
}

.block-overlay-full, .block-overlay-content {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.divider-block-intro {
	visibility: hidden;
	margin: 0.3125rem 0;
}




// Images
.img-regular-padding {
	padding: 0 1.25em;
	@media (max-width: 991.9px) {
		padding: 2.5em 1.25em;
	}
}

.img-no-padding {
	padding: 0;
	@media (max-width: 991.9px) {
		padding: 2.5em 0;
	}
}

.img-square-crop, .img-circle-crop {
	position: relative;
}
.img-square-crop::after, .img-circle-crop::after {
	content: "";
	display: block;
	padding-bottom: 100%;
}
.img-square-crop img, .img-circle-crop img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-repeat: no-repeat;
	@media (max-width: 991.9px) {
		padding: 2.5em;
	}
}

.img-circle-crop img {
	border-radius: 50%;
}

.img-overlay-sections {
	position: absolute;
	right: 2.5em;
	top: 0;
	@media (max-width: 991.9px) {
		position: static;
		padding: 2.5em 1.25em;
	}
}

.img-background-fill {
	display: flex;
	justify-content: stretch;
	align-items: flex-end;
	align-self: stretch;
	height: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	figure {
		display: flex;
		justify-content: stretch;
		align-items: flex-end;
		visibility: visible;
		width: 100%;
		height: 100%;
		img, a img {
			visibility: hidden;
			width: 100%;
		}
		figcaption {
			visibility: visible;
			width: 100%;
		}
	}
}
.block-single-image-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}



// Text One Column
.text-one-column-container {
	.block-button-group-container {
		margin-top: 1.25rem;
	}
}




// Large Text Callout
.large-text-callout-container {
	h2.large-text-callout-headline-text {
		font-weight: 400;
		font-size: 2em;
		color: $dark-gray-80;
	}
	h3.large-text-callout-headline-text {
		font-size: 1.75em;
	}
	h4.large-text-callout-headline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}

	h4.large-text-callout-subheadline-text {
		font-weight: 700;
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.large-text-callout-subheadline-text {
		font-weight: 700;
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.large-text-callout-subheadline-text {
		font-weight: 400;
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.large-text-callout-body-copy {
		p {
			font-size: 2.5em;
			line-height: 1.2em;
		}
	}
}




//Pull Quote
.pull-quote-container { 
	h2.pull-quote-headline-text {
		font-weight: 400;
		font-size: 2em;
		color: $dark-gray-80;
	}
	h3.pull-quote-headline-text {
		font-size: 1.75em;
	}
	h4.pull-quote-headline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-headline-text {
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h4.pull-quote-subheadline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-subheadline-text {
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.pull-quote-subheadline-text {
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.pull-quote-body-copy {
		p {
			font-size: 2.0em;
			line-height: 1.25em;
		}
	}
	cite.pull-quote-citation {
		display: block;
		color: $dark-gray-80;
		font-size: 1.5em;
		font-style: normal;
		font-weight: 700;
		line-height: 1em;
		margin: 0.625em 0 1.25em 0;
		padding: 0;
	}
}




// Text-With-Image Block
.block-text-image-body-column {
	padding: 0 2.5em !important;
	@media (max-width: 767.9px) {
		padding: 0 !important;
	}
	.block-text-image-headline-container {
		padding-bottom: 2.5em;
	}
}
.block-text-with-image-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}




// Text-With-Gallery Block
.block-container-text-with-gallery {
	.block-text-with-gallery-body-column {
	}
	.block-text-with-gallery-gallery-column {
		overflow: hidden;
		.swiper-slide {
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			-webkit-transform-style: preserve-3d;
			-moz-transform-style: preserve-3d;
			transform-style: preserve-3d;
		}
	}
}



// Accordion List Block
.block-container-accordion-list {
	.accordion-block {
		padding: 0 0 0.5rem 0;
		border-bottom: 1px solid $light-gray-15;
		.card {
			margin: 0;
			.btn-text {
				position: relative;
				padding: 1.5625rem 0 0.9375rem 0 ;
				width: 100%;
				font-family: $font1-400;
				font-size: 1.25rem;
				text-transform: initial;
				letter-spacing: 0;
				border-radius: 0;
				border-top: 1px solid $light-gray-15;
				&[aria-expanded="true"] {
					.fac { transform: rotate(225deg); }
				}
				.fa, .fac {
					display: inline-block;
					position: absolute;
					top: 1.5625rem;
					right: 0.625rem;
					transition: transform 300ms ease-in-out;
					transform: rotate(0deg);
					color: $cool-gray-10;
				}
			}
			.card-body {
				padding: 0 1.875rem 1.5625rem 0;
				p {
					margin: 0;
				}
			}
		}
	}
}






//Stat Facts Row
.stat-fact-number {
	display: inline;
	margin: 0;
	color: $dark-gray-80;
	font-size: 5em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-prefix {
	display: inline;
	margin: 0;
	color: $dark-gray-80;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-suffix {
	display: inline;
	margin: 0;
	color: $dark-gray-80;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-desc {
	margin: 0;
	font-size: 1.2em;
	line-height: 1;
}
.stat-fact-citation {
	margin: 0;
	padding-top: 0.625em;
	font-size: 0.9em;
	font-weight: 300;
	line-height: 1;
	color: $cool-gray-10;
}




//Image Callout Cards
.block-container-image-callout-cards {
	.card {
		border: none;
		border-radius: 0;
		.card-body {
			a {
				// color: $dark-gray-80;
				text-decoration: none;
				&:hover {
					color: $dark-gray-80;
					text-decoration: none;
					.image-callout-headline {
						color: $dark-gray-80;
						text-decoration: none;
					}
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $dark-gray-80;
					text-decoration: none;
					.image-callout-headline {
						color: $dark-gray-80;
						text-decoration: none;
					}
				}
			}
			p {
				font-size: 1.0em;
				line-height: 1.2em;
			}
			.image-callout-headline {
				margin: 0 !important;
				line-height: 1;
			}
			.image-callout-subheadline {
				margin: 0 !important;
				line-height: 1;
			}
		}
	}
}




//Image Icon Row
.image-icon-container {
	padding: 1.25em;
}
.image-icon-headline {
	margin: 0.3125em 0 0 0;
	color: $cool-gray-10;
}
.image-icon-link {
	&:hover {
		text-decoration: none;
	}
}
.image-icon-text p {
	color: $dark-gray-80;
	line-height: 1.2em;
	margin-top: 1.25em;
}
.image-icon-card {
	height: 100%;
	margin: 0;
	padding: 0 0 2.5em 0;
	display: flex;
	justify-content: center;
	align-items: flex-start; 
	.image-icon-card-img-top {
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		.image-icon-image {
			margin: 0;
			padding: 0;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.image-icon-card-body {
		width: 100%;
		margin: 0;
		padding: 0;
		align-self: flex-end;
		flex-grow: 0;
	}
	.image-icon-card-footer {
		margin: 0;
		padding: 0;
		align-self: flex-end;
	}
}





//Video Block
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0; 
	overflow: hidden;
	max-width: 100%;
	aspect-ratio: 1920 / 1080;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.video-embed-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.embed-container-overlaid {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}



//Image Gallery Block
.block-container-image-gallery {
	.block-image-gallery-thumb-container {
		padding: 0.3125em;
		.block-image-gallery-thumb {
		}
	}
	.block-image-gallery-masonry-grid-sizer,
	.block-image-gallery-masonry-grid-item { 
		padding: 0.3125em;
	}
}


//Multi Image Galleries Block
.block-multi-image-galleries-gallery-all-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	.block-multi-image-galleries-slider {
		.image-slider-wrapper {
			.image-slide {
				.image-slide-image {
					opacity: 1.0;
					border: none;
					-webkit-filter: blur(0px) grayscale(0%) contrast(100%);
					-moz-filter: blur(0px) grayscale(0%) contrast(100%);
					-ms-filter: blur(0px) grayscale(0%) contrast(100%);
					-o-bfilter: blur(0px) grayscale(0%) contrast(100%);
					filter: blur(0px) grayscale(0%) contrast(100%);
				}
			}
		}
	}
}




//Video Gallery Block
.block-container-video-gallery {
	.video-card-row {
		.video-card-container {
			.video-card {
				border-radius: 0;
				.card-img, .card-body, .card-footer {
					border-radius: 0;
				}
				.card-img {
					position: relative;
					// padding-bottom: 56.25%;
					// height: 0; 
					overflow: hidden;
					max-width: 100%;
					// aspect-ratio: 1920 / 1080;
					video {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
				.card-body {
					margin-bottom: 1.25rem;
					p {
						line-height: 1;
					}
				}
			}
		}
	}
}



//Background Image Callout Cards
.background-callout-card {
	width: 100%;
	border: none;
	border-radius: 0;
	.card-img {
		border-radius: 0;
	}
	.card-img-overlay {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding-bottom: 5vw;
		.background-callout-headline {
			color: $light-gray-5;
			font-size: 2.25em;
			line-height: 0.875;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}
		.background-callout-body p {
			font-size: 1.0em;
			line-height: 1.2em;
		}
	}
}



//Full Width Image/Video Block
.block-full-width-image-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	clip: rect(0, auto, auto, 0);
}



//Brand Logos Block
.block-container-brand-logos {
	.block-brand-logo-container {
		margin: 0;
		// margin: clamp(3.0rem, -webkit-calc(3.0rem + 2.0 * ((100vw - 36em) / 39)), 5.0rem);
		// margin: clamp(3.0rem, -moz-calc(3.0rem + 2.0 * ((100vw - 36em) / 39)), 5.0rem);
		// margin: clamp(3.0rem, calc(3.0rem + 2.0 * ((100vw - 36em) / 39)), 5.0rem);
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			.block-brand-logo-thumb {
				img {
					opacity: 0;
					//filter: blur(4px);
				}
			}
			.block-brand-logo-hover {
				img {
					opacity: 1;
					filter: blur(0);
				}
			}
		}
		.block-brand-logo-thumb {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 5.0rem;
			padding: 0 clamp(4.0rem, -webkit-calc(4.0rem + 1.0 * ((100vw - 36em) / 39)), 5.0rem);
			padding: 0 clamp(4.0rem, -moz-calc(4.0rem + 1.0 * ((100vw - 36em) / 39)), 5.0rem);
			padding: 0 clamp(4.0rem, calc(4.0rem + 1.0 * ((100vw - 36em) / 39)), 5.0rem);
			img {
				-webkit-transition: opacity 1.5s ease-in;
				-moz-transition: opacity 1.5s ease-in;
				-ms-transition: opacity 1.5s ease-in;
				-o-transition: opacity 1.5s ease-in;
				transition: opacity 1.5s ease-in;
			}
		}
		.block-brand-logo-hover {
			padding: 0 5.0rem;
			padding: 0 clamp(4.0rem, -webkit-calc(4.0rem + 1.0 * ((100vw - 36em) / 39)), 5.0rem);
			padding: 0 clamp(4.0rem, -moz-calc(4.0rem + 1.0 * ((100vw - 36em) / 39)), 5.0rem);
			padding: 0 clamp(4.0rem, calc(4.0rem + 1.0 * ((100vw - 36em) / 39)), 5.0rem);
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			img {
				opacity: 0;
				//filter: blur(4px);
				-webkit-transition: opacity 1.0s ease-out;
				-moz-transition: opacity 1.0s ease-out;
				-ms-transition: opacity 1.0s ease-out;
				-o-transition: opacity 1.0s ease-out;
				transition: opacity 1.0s ease-out;
			}
		}
	}
}



