header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	height: auto;
	z-index: 2;
	box-shadow: none;
	background: none;
	-webkit-transition: all 1.0s ease-out, transform 0.5s ease-out;
	-moz-transition: all 1.0s ease-out, transform 0.5s ease-out;
	-ms-transition: all 1.0s ease-out, transform 0.5s ease-out;
	-o-transition: all 1.0s ease-out, transform 0.5s ease-out;
	transition: all 1.0s ease-out, transform 0.5s ease-out;
	&.nav-down, &.nav-up {
		background: $white;
		.header-top-container {
			@media (min-width: 575.9px) {
				padding: 0.3125em 2.5em;
			}
			.header-logo {
				background-image: url('/assets/images/branding/KG_logo_BK.png') !important;
				width: 180px;
				height: 37px;
				margin: 0 1.875rem;
			}
			.link-text-nav {
				font-size: clamp(0.75rem, -webkit-calc(0.75rem + 0.25 * ((100vw - 36em) / 39)), 1rem);
				font-size: clamp(0.75rem, -moz-calc(0.75rem + 0.25 * ((100vw - 36em) / 39)), 1rem);
				font-size: clamp(0.75rem, calc(0.75rem + 0.25 * ((100vw - 36em) / 39)), 1rem);
			}
		}
	}
	&.nav-up {
		transform: translateY(-100%);
	}
	.header-top-container {
		background: none;
		padding: 0.9375em 2.5em;
		@media (max-width: 767.9px) {
			padding: 0.9375em 1.25em;
		}
		@media (max-width: 575.9px) {
			padding: 0.9375em 0;
		}
		.row {
			display: flex;
			justify-content: center;
			align-items: center;
			@media (max-width: 575.9px) {
				align-items: flex-start;
			}
		}
		.header-logo {
			display: inline-block;
			background: left center/contain no-repeat;
			text-indent: -9999px;
			width: 292px;
			height: 60px;
			margin: 0.9375em 0.625em;
			// z-index: 2;
			-webkit-transition: all 1.0s ease-out;
			-moz-transition: all 1.0s ease-out;
			-ms-transition: all 1.0s ease-out;
			-o-transition: all 1.0s ease-out;
			transition: all 1.0s ease-out;
			@media (max-width: 991.9px) {
				width: 208px;
				height: 53px;
			}
			@media (max-width: 767.9px) {
				width: 180px;
				height: 37px;
				margin: 0.9375em 0em;
			}
			@media (max-width: 575.9px) {
				margin: 0 1.875rem;
				// width: 120vw;
				// height: 25px;
			}
		}
	}
	.header-nav-container {
		background: none;
		padding: 0;
		.navbar {
			padding: 0;
		}
	}
}
