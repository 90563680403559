.page-title-full-width {
	position: absolute;
	background-color: $light-gray-5;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-repeat: no-repeat;
	background-position: center center;
	// background-attachment: scroll;
	filter: blur(4px) grayscale(25%);
	-webkit-filter: blur(4px) grayscale(25%);
	-moz-filter: blur(4px) grayscale(25%);
	-o-filter: blur(4px) grayscale(25%);
	opacity: 0.25;
	overflow: hidden;
}

.page-title-back {
	padding: 0;
	background-color: $dark-gray-80;
	position: relative;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	// background-attachment: scroll;
	max-width: 2200px;
	z-index: 2;
}

.page-title-video-container {
	padding: 0;
	position: relative;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	// background-attachment: scroll;
	max-width: 2200px;
	z-index: 3;
}

.page-title-overlay {
	// background: linear-gradient(180deg, rgba($light-gray-5,0.8) 0%, rgba($light-gray-5,0.2) 33%, rgba($light-gray-5,0) 66%, rgba($light-gray-5,0.8) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 4;
}

.page-title-container-spacer {
	min-height: 5.0rem;
}

.page-title-container {
	padding: 7.5rem 2.5rem 0.625rem 2.5rem;
	.page-title-backlink {
		display: block;
		font-family: "Avenir LT W01_45 Book", Helvetica, Calibri, 'Segoe UI', sans-serif;
		font-size: 0.75em;
		text-transform: uppercase;
		letter-spacing: 0.125em;
		color: $cool-gray-10;
		font-weight: 600;
		margin: 0 0 1.875rem 0;
		a {
			color: $cool-gray-10;
		}
		.fa {
			color: $light-gray-15;
		}
		&:hover {
			text-decoration: none;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			text-decoration: none;
		}
	}
	h1.page-title {
		margin: 0;
		padding: 0;
		line-height: 1;
	}
	.page-subtitle-container {
		padding: 0 0 1.875em 0;
	}
}
