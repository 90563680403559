// Basic Colors
$white: #ffffff;
$black: #000000;

// Grays
$light-gray-5: #f0f0f0;
$light-gray-15: #dddee1;
$light-gray-30: #bebebe;
$cool-gray-10: #63666a;
$dark-gray-60: #4f4f4f;
$dark-gray-80: #323232;
$dark-gray-90: #1e1e1e;


//Main Brand Fonts
$font1-100: 'Berthold Light', Helvetica, Calibri, 'Segoe UI', sans-serif;
$font1-400: 'Berthold Regular', Helvetica, Calibri, 'Segoe UI', sans-serif;
$font1-700: 'Berthold Bold', Helvetica, Calibri, 'Segoe UI', sans-serif;

//Icon Fonts
$font-icons1: 'Font Awesome 5 Free';
$font-icons2: 'KrauseGroup';


/******************************************************************************************
PIXEL TO REM CONVERSION TABLE - Root Size: 16px
-------------------------------------------------------------------------------------------
PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM
-------------------------------------------------------------------------------------------
10 = 0.625   |  20 = 1.25    |  30 = 1.875   |  40 = 2.5     |  50 = 3.125   |  60 = 3.75
11 = 0.6875  |  21 = 1.3125  |  31 = 1.9375  |  41 = 2.5625  |  51 = 3.1875  |  61 = 3.8125
12 = 0.75    |  22 = 1.375   |  32 = 2       |  42 = 2.625   |  52 = 3.25    |  62 = 3.875
13 = 0.8125  |  23 = 1.4375  |  33 = 2.0625  |  43 = 2.6875  |  53 = 3.3125  |  63 = 3.9375
14 = 0.875   |  24 = 1.5     |  34 = 2.125   |  44 = 2.75    |  54 = 3.375   |  64 = 4
15 = 0.9375  |  25 = 1.5625  |  35 = 2.1875  |  45 = 2.8125  |  55 = 3.4375
16 = 1       |  26 = 1.625   |  36 = 2.25    |  46 = 2.875   |  56 = 3.5
17 = 1.0625  |  27 = 1.6875  |  37 = 2.3125  |  47 = 2.9375  |  57 = 3.5625
18 = 1.125   |  28 = 1.75    |  38 = 2.375   |  48 = 3       |  58 = 3.625
19 = 1.1875  |  29 = 1.8125  |  39 = 2.4375  |  49 = 3.0625  |  59 = 3.6875
-------------------------------------------------------------------------------------------
******************************************************************************************/
