// News Article Teaser Cards
.news-card {
	width: 100%;
	margin: 0 0 2.5em 0;
	@media (max-width: 991.9px) {
	}
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-body {
			.news-article-headline {
				color: $dark-gray-80;
				text-decoration: none;
			}	
		}
		.card-footer a {
			color: $dark-gray-80;
			text-decoration: none;
			.fa, .fac {
				color: $dark-gray-80;
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			.news-article-headline {
				color: $dark-gray-80;
				text-decoration: none;
			}	
		}
		.card-footer a {
			color: $dark-gray-80;
			text-decoration: none;
			.fa, .fac {
				color: $dark-gray-80;
			}
		}
	}
	.card-img-top {
		aspect-ratio: 480 / 280;
		img {
			width: 100%;
			height: auto;
		}
	}
	.card-body {
		padding: 0;
		.news-article-date-container {
			margin-top: 0.625rem;
		}
		.news-article-headline {
			font-family: $font1-400;
			font-size: 1.25rem;
			color: $black;
			text-transform: none;
		}
		.news-article-teaser-copy {
			color: $cool-gray-10;
			font-size: 0.9375em;
			line-height: 1.25;
		}
	}
	.card-footer {
		padding: 0.3125em 0;
	}
}

/* Main Articles Page */
.card-news-container {
	margin: 0;
	padding: 2.5rem 2.5rem 5.0rem 2.5rem;
	// display: flex;
	// flex-direction: row;
	// justify-content: center;
	// align-items: flex-start;
}

.news-categories-container {
	margin: 0;
	padding: 0 2.5rem 0 2.5rem;
	@media (max-width: 1199.9px) {
		padding: 0 2.5rem 0 0;
	}
	@media (max-width: 991.9px) {
	}
	@media (max-width: 767.9px) {
		padding: 0 2.5rem 0 2.5rem;
	}
	.category-list-title {
		margin: 0 0 0.6125em 0;
		padding: 0 0 0.6125em 0;
		font-family: $font1-700;
		color: $cool-gray-10;
		font-size: 0.8175rem;
		font-weight: 400;
		// text-transform: uppercase;
		// letter-spacing: 0.2em;
		// border-bottom: 1px solid $black;
	}
	ul li {
		position: relative;
		display: block;
		font-family: $font1-400;
		// font-size: 0.75rem;
		font-weight: 400;
		// text-transform: uppercase;
		// letter-spacing: 0.1em;
		&::after {
			content: "\f001";
			font-family: $font-icons2;
			display: inline-block;
			font-size: 0.625em;
			font-weight: normal;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			text-rendering: auto;
			position: absolute;
			top: 1.5em;
			right: 0;
			color: $dark-gray-80;
		}
		&.active {
			a {
				color: $black;
			}
		}
		a {
			color: $black;
			&:hover {
				text-decoration: none;
				color: $dark-gray-80 !important;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				text-decoration: none;
				color: $dark-gray-80;
			}
		}
	}
}

.categories-sticky-container {
	margin: 0;
	padding: 2.5em 0 0 0;
}

/* News Detail Pages */
.entry-type-news {
	h1.page-title {
		font-size: clamp(3.0rem, -webkit-calc(3.0rem + 1.5 * ((100vw - 62em) / 39)), 4.5rem);
		font-size: clamp(3.0rem, -moz-calc(3.0rem + 1.5 * ((100vw - 62em) / 39)), 4.5rem);
		font-size: clamp(3.0rem, calc(3.0rem + 1.5 * ((100vw - 62em) / 39)), 4.5rem);
	}
}
.news-headline-container {
	padding: 0 2.5rem 0 2.5rem;
}
.news-body-container {
	padding: 0 2.5rem 12.5rem 2.5rem;
	.news-container {
		padding: 1.875rem 0 0 0;
		.video-thumb-container {
			margin: 0 0 2.5em 0;
			.news-video-featured-thumbnail {
				width: 100%;
				height: auto;
			}
		}
		.news-article-featured-image-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 2.5rem 0;
			.news-article-featured-image {
				width: 100%;
				height: auto;
			}
		}
	}
}

.news-subheadline-text {
	font-size: 1.5em;
	b {
		font-weight: 400;
		color: $dark-gray-80;
	}
}


/* News Block */
.block-latest-news-header-row {
	padding-bottom: 2.5rem;
}
.block-latest-news-category-container {
	margin-bottom: 2.5rem;
}

