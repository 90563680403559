html {
	box-sizing: border-box;
	background-color: $white;
	font-size: clamp(0.875em, calc(0.875em + 0.125 * ((100vw - 36em) / 39)), 1.25em);
	font-size: clamp(0.875em, -webkit-calc(0.875em + 0.125 * ((100vw - 36em) / 39)), 1.25em);
	font-size: clamp(0.875em, -moz-calc(0.875em + 0.125 * ((100vw - 36em) / 39)), 1.25em);
	text-rendering: auto;
}

*,
*::before,
*::after { box-sizing: inherit; }
*:active, *:focus {
	outline-style: none;
	outline-width: 0;
	outline: none !important;
}

body {
	position: relative;
	background-color: $white;
	margin: 0 !important;
	overflow-x: hidden;
}

main.page {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
}

.hide-vis {
	display: none;
}

.jumbotron-fluid {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 100%;
	// overflow-x: hidden;
}

.container-fluid {
	padding: 0;
	width: 100%;
	max-width: 100%;
	// overflow-x: hidden;
}

.container {
	//max-width: 100% !important;
}

.container-max {
	width: 88vw;
	max-width: 1980px;
	-webkit-transition: width 0.1s linear;
	-moz-transition: width 0.1s linear;
	-ms-transition: width 0.1s linear;
	-o-transition: width 0.1s linear;
	transition: width .1s linear;
	// @media (max-width: 1760px) { width: 81vw; }
	// @media (max-width: 1720px) { width: 82vw; }
	// @media (max-width: 1679px) { width: 83vw; }
	// @media (max-width: 1638px) { width: 84vw; }
	// @media (max-width: 1598px) { width: 85vw; }
	// @media (max-width: 1558px) { width: 86vw; }
	// @media (max-width: 1517px) { width: 87vw; }
	// @media (max-width: 1477px) { width: 88vw; }
	@media (max-width: 1436px) { width: 89vw; }
	@media (max-width: 1396px) { width: 90vw; }
	@media (max-width: 1356px) { width: 91vw; }
	@media (max-width: 1315px) { width: 92vw; }
	@media (max-width: 1275px) { width: 93vw; }
	@media (max-width: 1234px) { width: 94vw; }
	@media (max-width: 1194px) { width: 95vw; }
	@media (max-width: 1153px) { width: 96vw; }
	@media (max-width: 1113px) { width: 97vw; }
	@media (max-width: 1073px) { width: 98vw; }
	@media (max-width: 1032px) { width: 99vw; }
	@media (max-width: 991.9px) { width: 100vw; }
}

.col-md-border:not(:last-child) {
	@media (min-width: 992px) {
		border-right: 1px solid $light-gray-15;
	}
	@media (max-width: 991.9px) {
		border-bottom: 1px solid $light-gray-15;
	}
}

.col-md-border + .col-md-border {
	@media (min-width: 992px) {
		margin-left: -1px;
	}
	@media (max-width: 991.9px) {
		margin-top: -1px;
	}
}

thead.thead-branded {
	tr th {
		color: $cool-gray-10;
		font-weight: 400;
		font-size: 1.375em;
		border-top: none;
		border-bottom: 2px solid $dark-gray-80;
		@media (max-width: 991.9px) {
			font-size: 1.25em;
		}
		@media (max-width: 767.9px) {
			font-size: 1.125em;
		}
	}
}

tbody.tbody-branded {
	tr td {
		line-height: 1;
		border-top: 1px dotted $light-gray-15;
		@media (max-width: 991.9px) {
			font-size: 0.875em;
		}
		@media (max-width: 767.9px) {
			font-size: 0.75em;
		}
	}
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $dark-gray-80;
}
