.body, body { 
	font-family: $font1-400;
	//font-weight: 400;
	font-style: normal;
	line-height: 1;
	color: $cool-gray-10;
}

.body {
	p {
		font-size: 1.3125em;
		line-height: 1.2;
		a {
			color: $cool-gray-10;
			text-decoration: underline;
			text-decoration-thickness: from-font;
			&:hover {
				color: $dark-gray-80;
				text-decoration: underline;
				text-decoration-thickness: from-font;
			}
			.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
				color: $black;
				text-decoration: underline;
				text-decoration-thickness: from-font;
			}
		}
	}
	ul {
		list-style: none; /* Remove default bullets */
		margin: 1.0em 0;
	}

	ul li::marker {
		content: "\2580";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: $cool-gray-10; /* Change the color */
		// font-weight: bold; /* If you want it to be bold */
		// display: inline-block; /* Needed to add space between the bullet and the text */
		// width: 2.5em;  /* Also needed for space (tweak if needed) */
		// margin-left: -2.5em; /* Also needed for space (tweak if needed) */
		// transform: translateY(0.25em);
		font-size: 0.75em;
	}

	ul li {
		line-height: 1;
		margin: 0.66em -0.5em;
		padding-left: 0.5em;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	ul li a {
		// font-weight: 400;
		// text-decoration: underline;
		// text-decoration-thickness: from-font;
		// color: $black !important;
	}

	ul li a:hover {
		// color: $dark-gray-80 !important;
	}

	ol li {
		line-height: 1.25;
		margin: 0.66em 0;
	}

	ul.unstyled {
		list-style-type: none;
		margin: 0 0 2.5em 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0.625em 0;
			line-height: 1;
			// border-bottom: 1px solid $light-gray-15;
			&:first-child { 
				// padding-top: 0; 
			}
			&:last-child { 
				border-bottom: none; 
			}
			&::marker {
				content: "";
				display: none;
			}
			b {
				color: $black;
				// font-weight: 600;
			}
		}
	}

	ul.list-2column {
		// margin-top: 0;
		// padding-top: 0.9375em;
		// border-top: 1px solid $light-gray-15;
		column-count: 2;
		column-gap: 2.5em;
		column-rule: 1px dotted $light-gray-5;
		@include media-breakpoint-only(lg) {
			column-count: 1;
		}
		@include media-breakpoint-only(xs) {
			column-count: 1;
		}
	}
}

p {
	font-size: 1.3125em;
	line-height: 1.2;
}

p.lead, .lead p {
	font-family: $font1-400;
	font-size: 2.25em;
	font-size: clamp(1.75rem, -webkit-calc(1.75rem + 0.5 * ((100vw - 36em) / 39)), 2.25em);
	font-size: clamp(1.75rem, -moz-calc(1.75rem + 0.5 * ((100vw - 36em) / 39)), 2.25em);
	font-size: clamp(1.75rem, calc(1.75rem + 0.5 * ((100vw - 36em) / 39)), 2.25em);
	line-height: 1.2;
}

p.text-small, .text-small p, .text-small {
	font-size: 0.8em;
	line-height: 1.25;
}

b, strong {
	// font-weight: 700;
	font-family: $font1-700;
}
strong {
	color: $black;
}

a {
	color: $black;
	text-decoration: none;
	&:hover {
		color: $dark-gray-80;
		text-decoration: underline;
		text-decoration-thickness: from-font;
	}
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		color: $black;
		text-decoration: underline;
		text-decoration-thickness: from-font;
	}
}

h1, h2, h3, h4, h5, h6 {
	overflow-wrap: break-word;
	// hyphens: auto;
	// -ms-hyphens: auto;
	// -moz-hyphens: auto;
	letter-spacing: -0.09rem;
	b, strong {
		font-family: $font1-700;
		font-weight: 700;
		color: $black;
	}
}

h4, h5, h6 {
	letter-spacing: 0rem;
}

h1.display-1 {
	font-family: $font1-700;
	// font-weight: 700;
	font-size: 6.75rem;
	font-size: clamp(4.75rem, -webkit-calc(4.75rem + 2.0 * ((100vw - 62em) / 39)), 6.75rem);
	font-size: clamp(4.75rem, -moz-calc(4.75rem + 2.0 * ((100vw - 62em) / 39)), 6.75rem);
	font-size: clamp(4.75rem, calc(4.75rem + 2.0 * ((100vw - 62em) / 39)), 6.75rem);
	line-height: 0.875;
	color: $black;
	b, strong {
		font-weight: 700;
	}
}

h1.display-2 {
	font-family: $font1-400;
	// font-weight: 400;
	font-size: 4.5rem;
	font-size: clamp(3.5rem, -webkit-calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	font-size: clamp(3.5rem, -moz-calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	font-size: clamp(3.5rem, calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	line-height: 0.875;
	color: $dark-gray-80;
	b, strong {
		font-weight: 700;
	}
}


h1.display-3 {
	font-family: $font1-700;
	// font-weight: 700;
	font-size: 6.75rem;
	font-size: clamp(4.75rem, -webkit-calc(4.75rem + 2.0 * ((100vw - 62em) / 39)), 6.75rem);
	font-size: clamp(4.75rem, -moz-calc(4.75rem + 2.0 * ((100vw - 62em) / 39)), 6.75rem);
	font-size: clamp(4.75rem, calc(4.75rem + 2.0 * ((100vw - 62em) / 39)), 6.75rem);
	line-height: 0.875;
	color: $black;
	b, strong {
		// font-weight: 100;
	}
}

h1.display-4 {
	font-family: $font1-400;
	//font-weight: 400;
	font-size: 4.5rem;
	font-size: clamp(3.5rem, -webkit-calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	font-size: clamp(3.5rem, -moz-calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	font-size: clamp(3.5rem, calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	line-height: 0.875;
	color: $dark-gray-80;
	b, strong {
		font-weight: 700;
	}
}

h1 {
	font-family: $font1-400;
	//font-weight: 700;
	font-size: 4.5rem;
	font-size: clamp(3.5rem, -webkit-calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	font-size: clamp(3.5rem, -moz-calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	font-size: clamp(3.5rem, calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	line-height: 1;
	color: $black;
	@media (min-width: 991.9px) {
	}
	@media (max-width: 991.9px) {
	}
}

h2 {
	font-family: $font1-400;
	//font-weight: 400;
	font-size: 4.5rem;
	font-size: clamp(3.5rem, -webkit-calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	font-size: clamp(3.5rem, -moz-calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	font-size: clamp(3.5rem, calc(3.5rem + 1.0 * ((100vw - 62em) / 39)), 4.5rem);
	line-height: 1;
	color: $black;
	b, strong {
		font-weight: 700;
	}
	@media (min-width: 991.9px) {
	}
	@media (max-width: 991.9px) {
	}
}

h3 {
	font-family: $font1-400;
	//font-weight: 400;
	font-size: 4.5rem;
	font-size: clamp(2.75rem, -webkit-calc(2.75rem + 0.75 * ((100vw - 62em) / 39)), 3.5rem);
	font-size: clamp(2.75rem, -moz-calc(2.75rem + 0.75 * ((100vw - 62em) / 39)), 3.5rem);
	font-size: clamp(2.75rem, calc(2.75rem + 0.75 * ((100vw - 62em) / 39)), 3.5rem);
	line-height: 1;
	color: $black;
	b, strong {
		font-weight: 700;
	}
}

h4 {
	font-family: $font1-400;
	//font-weight: 400;
	font-size: 1.3125rem;
	font-size: clamp(2.0rem, -webkit-calc(2.0rem + 0.5 * ((100vw - 62em) / 39)), 2.5rem);
	font-size: clamp(2.0rem, -moz-calc(2.0rem + 0.5 * ((100vw - 62em) / 39)), 2.5rem);
	font-size: clamp(2.0rem, calc(2.0rem + 0.5 * ((100vw - 62em) / 39)), 2.5rem);
	line-height: 1.125;
	color: $black;
	margin-bottom: 0.4375rem;
	b, strong {
		font-weight: 700;
	}
}

h5 {
	font-family: $font1-700;
	//font-weight: 700;
	font-size: clamp(1.25rem, -webkit-calc(1.25rem + 0.25 * ((100vw - 62em) / 39)), 1.5rem);
	font-size: clamp(1.25rem, -moz-calc(1.25rem + 0.25 * ((100vw - 62em) / 39)), 1.5rem);
	font-size: clamp(1.25rem, calc(1.25rem + 0.25 * ((100vw - 62em) / 39)), 1.5rem);
	line-height: 1.125;
	color: $black;
}

h6 {
	font-family: $font1-700;
	//font-weight: 400;
	font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.25 * ((100vw - 62em) / 39)), 1.25rem);
	font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.25 * ((100vw - 62em) / 39)), 1.25rem);
	font-size: clamp(1.0rem, calc(1.0rem + 0.25 * ((100vw - 62em) / 39)), 1.25rem);
	line-height: 1.125;
	color: $black;
}

.text-light {
	color: $light-gray-5 !important;
	p, h1 ,h2 ,h3, h4,h5, h6, ul li::before, ul li::before {
		color: $light-gray-5 !important;
	}
	a {
		color: $light-gray-5 !important;
		text-decoration: none;
		&:hover {
			color: $light-gray-5 !important;
			text-decoration: underline;
			text-decoration-thickness: from-font;
		}
		.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
			color: $light-gray-15 !important;
			text-decoration: underline;
			text-decoration-thickness: from-font;
		}
	}
}

h1.large-quote {
	vertical-align:bottom;
	font-size: 5.5em;
	line-height: 1;
}

h2.large-quote {
	vertical-align:bottom;
	font-size: 4.5em;
	line-height: 1;
}

h2.page-headline-text {
	margin-bottom: 0.625em;
}

h4.page-subheadline-text {
	margin-top: 0.625em;
}

h6.header-xs {
	font-size: 0.875em;
}

h3.category-headline {
	font-family: $font1-700;
	color: $cool-gray-10;
	line-height: 1;
	margin-bottom: 0.25em;
}

.social-icons {
	.social-icon {
		margin: 0 1.25rem 0 0;
		font-family: $font-icons2;
		color: $cool-gray-10;
		line-height: 1;
		&:hover {
			color: $dark;
			text-decoration: none;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $black;
			text-decoration: none;
		}
	}
	.social-desc {
		display: none;
	}
}

