/* General Form Inputs */
.form-control {
	background-color: $light-gray-5;
	padding: 0.625em;
	border: 1px solid $light-gray-5;
	border-radius: 0 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.custom-checkbox
.custom-control-input:checked~.custom-control-label::after {
	background-color: $dark-gray-80;
}
.form-control:focus {
	background-color: $light-gray-5;
	border-color: $light-gray-15;
	border: 1px solid $light-gray-15;
	box-shadow: inset 0 1px 1px rgba($dark-gray-80, 0.075), 0 0 0.5em rgba($dark-gray-80, 0.6);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

button.active.focus, button.active:focus, button.focus, button.focus:active, button:active:focus, button:focus {
	outline: 0 !important;
	outline-offset: 0  !important;
	background-image: none  !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

input[type="text"],
input[type="button"],
input[type="submit"],
input[type=”email”],
input[type=”tel”],
input[type=”number”] {
	// width: 100%;
	padding: 0.625em;
	border: none;
	border-radius: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

textarea {
	width: 100%;
	padding: 0.625em;
	border: none;
	border-radius: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

.form-label-required {
	color: $dark-gray-80;
}

.form-check-input:not(:checked), .form-check-input:checked {
	// position: absolute;
	// pointer-events: none;
	// opacity: 0;
}

.btn-group-toggle {
	margin: 0 1.25em;
}

.btn-outline-group {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-weight: 400 !important;
	text-transform: none !important;
	letter-spacing: 0 !important;
	background-color: none !important;
	color: $dark-gray-80 !important;
	border: none !important;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $dark-gray-80 !important;
		border: none !important;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $dark-gray-80 !important;
		border: none !important;
		box-shadow: 0 !important;
		outline: none !important;
	}
}

.btn-outline-group:not(:disabled):not(.disabled):active, 
.btn-outline-group:not(:disabled):not(.disabled).active, 
.show>.btn-outline-group.dropdown-toggle {
	color: $light-gray-5 !important;
	background-color: $dark-gray-80 !important;
 }

label {
	font-family: $font1-400;
	font-weight: 400;
	font-size: clamp(0.875rem, -webkit-calc(0.875rem + 0.125 * ((100vw - 36em) / 39)), 1.0rem);
	font-size: clamp(0.875rem, -moz-calc(0.875rem + 0.125 * ((100vw - 36em) / 39)), 1.0rem);
	font-size: clamp(0.875rem, calc(0.875rem + 0.125 * ((100vw - 36em) / 39)), 1.0rem);
}

label.required:after  {
 color: $black;
}
